export const rangeMap = <T>(
  limit: number,
  callback: (index: number) => T
): T[] => {
  const result: T[] = [];
  for (let i = 0; i < limit; i++) {
    result.push(callback(i));
  }
  return result;
};
